<template>
    <div>
        <v-container fluid class="pa-6 ">
            <DatatableV2 :displayTitle="false" :flat="false" :url="apiUrl" @goToCampaignDashboard="goToCampaignDashboard"/>
        </v-container>
    </div>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2';
import GenericDataService from '@/services/GenericDataService';

export default {
    name: "CampaignSMSList",
    components: {DatatableV2},
    data() {
        return {
            apiUrl: "/retroplanning/list?type=CAMP_OUT_P&sousType=campSMSout",
        }
    },
    methods: {
        goToCampaignDashboard(object, actions) {
            if(object.operation_id && object.operation_id.id) {
                this.$router.push({ path: actions.actions.SHOW.route.replace('{{id}}', object.id).replace('{{operation_id}}', object.operation_id.id) });
            } else {
                this.$router.push({ path: actions.actions.SHOW_WITHOUT_OPE.route.replace('{{id}}', object.id) });
            }
        }
    }
}
</script>